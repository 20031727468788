import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'

class LinkSelect extends React.Component {

  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.renderValue = this.renderValue.bind(this);
  }

  onSelect(selectValue) {
    if (selectValue.url === undefined) {return}
    window.location.href = selectValue.url
  }

  renderValue(option) {
    return (
      <span style={this.renderValueStyle()} >
        <strong>{this.props.renderValue(option.label)}</strong>
      </span>
    );
  }

  renderValueStyle() {
		return {
			color: this.props.themeColor || '#000',
		}
	}

  getOptions() {
    return this.props.options.map((option) =>
      Object.assign(option, { label: this.getLabel(option.value) })
    )
  }

  getLabel(value) {
    return value.replace(/_/g, ' ');
  }

  buildValue() {
    if(this.props.value === null) { return null; }
    return { label: this.getLabel(this.props.value), value: this.props.value }
  }

	render() {
		return (
			<React.Fragment>
				<Select
					{...this.props}
          options={this.getOptions()}
					onChange={this.onSelect}
					valueRenderer={this.renderValue}
					placeholder='type or select...'
          value={this.buildValue()}
				/>
			</React.Fragment>
		)
	}
}

LinkSelect.defaultProps = {
  renderValue: (label) => label
};
export default LinkSelect

import React from "react"
import PropTypes from "prop-types"
import WorkerSelect from "./WorkerSelect"

class WorkerSelectContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      offerMethod: 'auto',
    }
  }

  handleOfferMethodSelect (event) {
    this.setState({offerMethod: event.target.value});
  }

  renderWorkerSelect() {
    if(this.state.offerMethod !== 'manual') { return null; }
    return <WorkerSelect {...this.props} />
  }

  render () {
    return (
      <React.Fragment>
        <div className="mb-3">
          <label>Which carers should be offered this { this.props.bookingType }?</label>
          <select
            className="form-control"
            onChange={this.handleOfferMethodSelect.bind(this)}
            value={this.state.offerMethod}
          >
            <option value="auto">Offer to all qualified carers</option>
            <option value="manual">Manually select carers to offer</option>
          </select>
        </div>
        { this.renderWorkerSelect() }
      </React.Fragment>
    );
  }
}

WorkerSelectContainer.propTypes = {
  workers: PropTypes.array
};
export default WorkerSelectContainer

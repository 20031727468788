import React from "react"
import PropTypes from "prop-types"
import LinkSelect from "../LinkSelect"
import generateURL from '../../services/generateURL'

const BOOKING_STATES = {
  pending: 'Pending',
  created: 'Awaiting carer selection',
  worker_confirmed: 'Awaiting confirmation',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  client_cancelled: 'Cancelled by client',
};

const DEFAULT_LABEL = 'All active'

class BookingStateFilter extends React.Component {

  getValue() {
    const url = new URL(this.props.baseURL);
    const key = url.searchParams.get('state');
    if(key === null) return DEFAULT_LABEL
    return this.getLabel(key);
  }

  getLabel(value) {
    return BOOKING_STATES[value];
  }

  getOptions() {
    const options = Object.keys(BOOKING_STATES).map((key) => (
      {
        value: this.getLabel(key),
        url: generateURL(this.props.baseURL, (s) => s.set('state', key))
      }
    ))
    const baseOptions = [
      {value: DEFAULT_LABEL, url: generateURL(this.props.baseURL, (s) => s.delete('state'))}
    ]
    return baseOptions.concat(options);
  }

  render () {
    return (
      <React.Fragment>
        <label>State</label>
        <LinkSelect
          options={this.getOptions()}
          value={this.getValue()}
          isSearchable={false}
        />
      </React.Fragment>
    );
  }
}

export default BookingStateFilter

import React from "react"
import PropTypes from "prop-types"
import generateURL from '../../services/generateURL'
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';


class OldBookingDateFilter extends React.Component {

  state = {
    startDate: null,
    endDate: null,
    focusedInput: null,
  }

  handleDateChange({startDate, endDate}) {
    this.setState({ startDate, endDate })

    if(startDate !== null && endDate !== null) {
      const url = generateURL(this.props.baseURL, (s) => {
        s.set('starts_at_day', startDate.date())
        s.set('starts_at_month', startDate.month())
        s.set('starts_at_year', startDate.year())
        s.set('ends_at_day', endDate.date())
        s.set('ends_at_month', endDate.month())
        s.set('ends_at_year', endDate.year())
      })
      window.location.href = url;
    }
  }

  handleFocusChange(focusedInput) {
    this.setState({ focusedInput })
  }

  getStartDate() {
    if(this.state.startDate !== null) { return this.state.startDate; }
    return this.getCurrentDate('starts_at')
  }

  getEndDate() {
    if(this.state.endDate !== null) { return this.state.endDate; }
    return this.getCurrentDate('ends_at')
  }

  getCurrentDate(date) {
    const url = new URL(this.props.baseURL);
    const day = url.searchParams.get(`${date}_day`);
    if(day === null) { return null; }
    const month = url.searchParams.get(`${date}_month`);
    const year = url.searchParams.get(`${date}_year`);
    return  moment({ day, month, year });
  }

  render () {
    return (
      <React.Fragment>
        <label>Date range</label>
        <DateRangePicker
          startDate={this.getStartDate()}
          startDateId="your_unique_start_date_id"
          endDate={this.getEndDate()}
          endDateId="your_unique_end_date_id"
          onDatesChange={this.handleDateChange.bind(this)}
          focusedInput={this.state.focusedInput}
          onFocusChange={this.handleFocusChange.bind(this)}
          minimumNights={0}
          displayFormat="DD MMM YYYY"
          isOutsideRange={(x) => false}
          numberOfMonths={1}
        />
      </React.Fragment>
    );
  }
}

export default OldBookingDateFilter

import React from "react"
import PropTypes from "prop-types"
import LinkSelect from "../LinkSelect"
import generateURL from '../../services/generateURL'

class BookingTypeFilter extends React.Component {

  getValue() {
    if (this.props.isRegularPlacements) {
      return 'Regular Placements';
    }
    return 'Casual Bookings';
  }

  getOptions() {
    const baseURL = new URL(this.props.baseURL);
    const cleanURL = baseURL.origin + baseURL.pathname
    return [
      {value: 'Casual Bookings', url: cleanURL},
      {value: 'Regular Placements', url: generateURL(cleanURL, (s) => s.set('regular_placements', 1))},
    ];
  }

  render () {
    return (
      <React.Fragment>
        <label>Booking type</label>
        <LinkSelect
          options={this.getOptions()}
          value={this.getValue()}
          isSearchable={false}
        />
      </React.Fragment>
    );
  }
}

export default BookingTypeFilter

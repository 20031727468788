import React from "react"
import PropTypes from "prop-types"

class SpinnerButton extends React.Component {
  state = {
    clicked: false
  }

  renderSpinner() {
    if(!this.state.clicked) { return; }
    return <i className='fa fa-circle-o-notch fa-spin'></i>
  }

  disabledClass() {
    return !this.state.clicked ? 'disabled' : '';
  }

  handleClick() {
    console.log('click handled')
    this.setState({clicked: true})
    this.inputLink.click();
  }

  render () {
    return (
      <React.Fragment>
        <button
          className={this.props.class}
          onClick={ this.handleClick.bind(this) }
          disabled={this.state.clicked}
        >
          { this.props.text } { this.renderSpinner() }
        </button>
        <a
          action={this.props.action}
          data-method={this.props.method}
          rel="nofollow"
          href={this.props.path}
          hidden
          ref={input => this.inputLink = input}
        >
        </a>
      </React.Fragment>
    );
  }
}

SpinnerButton.defaultProps = {
  action: "create",
  method: "post"
}

export default SpinnerButton

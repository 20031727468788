import React from "react"
import PropTypes from "prop-types"
import BookingTypeFilter from "./filters/BookingTypeFilter"
import BookingStateFilter from "./filters/BookingStateFilter"
import BookingDateFilter from "./filters/BookingDateFilter"
// import BookingStartDateFilter from "./filters/BookingDateFilter"

class BookingsFilter extends React.Component {

  isRegularPlacements() {
    const url = new URL(this.props.baseURL);
    const regularPlacementOption = url.searchParams.get('regular_placements');
    return regularPlacementOption !== null;
  }

  renderDateFilter(isRegularPlacements) {
    if(isRegularPlacements) { return null; }
    return (
      <BookingDateFilter
        {...this.props}
      />
    );
  }

  render () {
    const isRegularPlacements = this.isRegularPlacements();
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-lg-3 col-md-6 my-2'>
            <BookingTypeFilter
              {...this.props}
              isRegularPlacements={isRegularPlacements}
            />
          </div>
          <div className='col-lg-3 col-md-6 my-2'>
            <BookingStateFilter
              {...this.props}
            />
          </div>
          <div className='col-md-auto my-2'>
            { this.renderDateFilter(isRegularPlacements) }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BookingsFilter

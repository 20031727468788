import React from "react"
import PropTypes from "prop-types"
import moment from 'moment'

class BookingDateFilter extends React.Component {
  state = {
    startDate: null,
    endDate: null
  }

  handleStartDateChange = (event) => {
    const startDate = event.target.value ? moment(event.target.value) : null
    this.setState({ startDate })
    if (this.props.onDateChange) {
      this.props.onDateChange({ startDate, endDate: this.state.endDate })
    }
  }

  handleEndDateChange = (event) => {
    const endDate = event.target.value ? moment(event.target.value) : null
    this.setState({ endDate })
    if (this.props.onDateChange) {
      this.props.onDateChange({ startDate: this.state.startDate, endDate })
    }
  }

  handleApplyFilter = () => {
    const { startDate, endDate } = this.state
    if (startDate && endDate) {
      const url = new URL(this.props.baseURL)
      url.searchParams.set('starts_at_day', startDate.date())
      url.searchParams.set('starts_at_month', startDate.month())
      url.searchParams.set('starts_at_year', startDate.year())
      url.searchParams.set('ends_at_day', endDate.date())
      url.searchParams.set('ends_at_month', endDate.month())
      url.searchParams.set('ends_at_year', endDate.year())
      window.location.href = url.toString()
    }
  }

  componentDidMount() {
    this.initializeDatesFromURL();
  }

  initializeDatesFromURL() {
    const url = new URL(this.props.baseURL);
    const startsAtDay = url.searchParams.get('starts_at_day');
    const startsAtMonth = url.searchParams.get('starts_at_month');
    const startsAtYear = url.searchParams.get('starts_at_year');
    const endsAtDay = url.searchParams.get('ends_at_day');
    const endsAtMonth = url.searchParams.get('ends_at_month');
    const endsAtYear = url.searchParams.get('ends_at_year');

    if (startsAtDay && startsAtMonth && startsAtYear) {
      this.setState({
        startDate: moment(`${startsAtYear}-${parseInt(startsAtMonth) + 1}-${startsAtDay}`, 'YYYY-M-D')
      });
    }

    if (endsAtDay && endsAtMonth && endsAtYear) {
      this.setState({
        endDate: moment(`${endsAtYear}-${parseInt(endsAtMonth) + 1}-${endsAtDay}`, 'YYYY-M-D')
      });
    }
  }

  render () {
    const { startDate, endDate } = this.state
    return (
      <React.Fragment>
        <div className="d-flex flex-column">
          <div className="d-flex flex-column flex-md-row align-items-md-end mb-2">
            <div className="mb-2 mb-md-0 me-md-3">
              <label>Earliest Date</label>
              <input
                className="form-control"
                type="date"
                onChange={this.handleStartDateChange}
                value={startDate ? startDate.format('YYYY-MM-DD') : ''}
              />
            </div>
            <div className="mb-2 mb-md-0 mx-md-3">
              <label>Latest Date</label>
              <input
                className="form-control"
                type="date"
                onChange={this.handleEndDateChange}
                value={endDate ? endDate.format('YYYY-MM-DD') : ''}
              />
            </div>
          </div>
          {startDate && endDate && (
            <div className="mt-2 w-100 w-md-auto">
              <button className="btn btn-outline-primary" onClick={this.handleApplyFilter}>Apply Date range</button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

BookingDateFilter.propTypes = {
  onDateChange: PropTypes.func,
  baseURL: PropTypes.string.isRequired
}

export default BookingDateFilter

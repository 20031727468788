import React from "react"
import PropTypes from "prop-types"
import {CopyToClipboard} from 'react-copy-to-clipboard';

class ClipboardCopier extends React.Component {
  state = {
    copied: false,
  };


  buttonText() {
    if(this.state.copied) {
      return "Copied"
    }
    return `Copy ${this.props.copyTitle}`
  }

  render () {
    return (
      <React.Fragment>
        <CopyToClipboard
          text={this.props.copyValue}
          onCopy={() => this.setState({copied: true})}
        >
          <button
            className={this.props.buttonClasses}
          >
            { this.buttonText() }
          </button>
        </CopyToClipboard>
      </React.Fragment>
    );
  }
}

export default ClipboardCopier

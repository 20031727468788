import React from "react"
import PropTypes from "prop-types"
import { SingleDatePicker } from 'react-dates';
import moment from 'moment'
import _ from 'lodash'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';


class DateInput extends React.Component {

  state = {
    date: null,
    focused: null,
  }

  handleDateChange(date) {
    this.setState({ date })
  }

  handleFocusChange({focused}) {
    this.setState({ focused })
  }

  renderInputs() {
    const date = this.getDate();
    if(_.isNil(date)) { return null; }
    return (
      <div>
        <input hidden readOnly id="booking_date_3i" name="booking[date(3i)]" value={date.date()} />
        <input hidden readOnly id="booking_date_2i" name="booking[date(2i)]" value={date.month() + 1} />
        <input hidden readOnly id="booking_date_1i" name="booking[date(1i)]" value={date.year()} />
      </div>
    );
  }

  getDate() {
    if(this.state.date) { return this.state.date }
    if(this.props.initialDate) { return moment(this.props.initialDate); }
  }

  render () {
    return (
      <React.Fragment>
        <SingleDatePicker
          date={this.getDate()}
          onDateChange={this.handleDateChange.bind(this)}
          focused={this.state.focused}
          onFocusChange={this.handleFocusChange.bind(this)}
          displayFormat="DD MMM YYYY"
          numberOfMonths={1}
        />
        { this.renderInputs() }
      </React.Fragment>
    );
  }
}

export default DateInput

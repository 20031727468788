import React from "react"
import PropTypes from "prop-types"
import AddressAutocomplete from './AddressAutocomplete'

class AddressAutocompleteContainer extends React.Component {


  render () {
    if(typeof google === 'undefined') {
      console.log('google var is not defined')
      return(
        <React.Fragment>
          <label
            className="control-label string required"
            htmlFor={`${this.props.modelName}_address_attributes_full_address`}>
            <abbr title="required">*</abbr> Full address where care is required
          </label>
          <input
            id={`${this.props.modelName}_address_attributes_full_address`}
            name={`${this.props.modelName}[address_attributes][full_address]`}
            className='form-control'
            defaultValue={ this.props.existing_address }
          />
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <AddressAutocomplete {...this.props} />
        </React.Fragment>
      );
    }
  }
}

AddressAutocompleteContainer.propTypes = {
};

export default AddressAutocompleteContainer

import React from "react"
import PropTypes from "prop-types"
import Autocomplete from 'react-google-autocomplete';

class AddressAutocomplete extends React.Component {
  componentDidMount() {
    var input = document.getElementById(`${this.props.modelName}_address_attributes_full_address`);
    if(input) {
      google.maps.event.addDomListener(input, 'keydown', function(event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      });
    }
  }

  render () {
    return (
      <React.Fragment>
        <div className={`form-group string required ${this.props.modelName}_address_full_address`}>
          <label
            className="control-label string required"
            htmlFor={`${this.props.modelName}_address_attributes_full_address`}>
            <abbr title="required">*</abbr> Full address where care is required
          </label>
          <Autocomplete
            onPlaceSelected={(place) => {
              console.log(place);
            }}
            id={`${this.props.modelName}_address_attributes_full_address`}
            name={`${this.props.modelName}[address_attributes][full_address]`}
            className='form-control'
            types={['address']}
            componentRestrictions={{country: this.props.country_code}}
            defaultValue={ this.props.existing_address }
          />
        </div>
      </React.Fragment>
    );
  }
}

AddressAutocomplete.propTypes = {
  greeting: PropTypes.string,
  modelName: PropTypes.string
};
export default AddressAutocomplete

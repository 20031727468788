import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/lib/Async'

class AsyncSelector extends React.Component {
  state = { inputValue: '' };

  handleInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  };

  getLinkURL(id) {
    return this.props.linkURI.replace('ID', id);
  }

  onSelect(selectValue) {
    if (selectValue.id === undefined) {return}
    window.location.href = this.getLinkURL(selectValue.value);
  }

  promiseOptions(inputValue) {
    const fetchURI = this.props.fetchURI;
    return new Promise((resolve) => {
      const response =
        fetch(`${fetchURI}?q=${inputValue}`, {
          credentials: 'same-origin'
        })
          .then((response) => response.json())
          .then((data) => resolve(data));
    });
  }

  render() {
    return (
      <React.Fragment>
        <AsyncSelect
          cacheOptions
          onChange={this.onSelect.bind(this)}
          loadOptions={this.promiseOptions.bind(this)}
          placeholder="Search the client's name"
        />
      </React.Fragment>
    );
  }
}

export default AsyncSelector

import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'

class TagSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
    }
    this.handleChange = this.handleChange.bind(this)
  }

  getOptions() {
    return this.props.tags.map((tag) => (
      {
        value: tag,
        label: tag
      }
    ))
  }

  handleChange(selectedOptions) {
    this.setState({ selectedOptions: selectedOptions });
  }

  renderInputs() {
    return this.state.selectedOptions.map((option, index) => (
      <input
        name={`tags[]`}
        key={option.value}
        value={option.value}
        readOnly
        hidden
      />
    ))
  }

  render () {
    const { selectedOptions } = this.state;
    return (
      <React.Fragment>
        <label>{ this.props.tagLabel }</label>
        <Select
          id='tag_ids'
          value={selectedOptions}
          options={this.getOptions()}
          onChange={this.handleChange}
          closeMenuOnSelect={false}
          isMulti
          placeholder="Select or type tags.."
        />
        { this.renderInputs() }
      </React.Fragment>
    );
  }
}

TagSelect.defaultProps = {
  tagLabel: "Select tags to offer to groups of carers"
}

TagSelect.propTypes = {
  tags: PropTypes.array
};
export default TagSelect

import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'
import TagSelect from "./TagSelect";
import _ from 'lodash'

class WorkerSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: [],
    }
    this.handleChange = this.handleChange.bind(this)
  }

  getOptions() {
    return this.props.workers.map((worker) => (
      {
        value: worker.id,
        label: `${worker.first_name} ${worker.last_name}`
      }
    ))
  }

  handleChange(selectedOptions) {
    this.setState({ selectedOptions: selectedOptions });
  }

  renderInputs() {
    return this.state.selectedOptions.map((option, index) => (
      <input
        name={`worker_ids[]`}
        key={index}
        value={option.value}
        readOnly
        hidden
      />
    ))
  }

  renderTagSelect() {
    if (_.isEmpty(...this.props.tags)) { return null; }
    return (
      <div className="mt-3">
        <TagSelect {...this.props} />
      </div>
    );
  }

  render () {
    const { selectedOptions } = this.state;
    return (
      <React.Fragment>
        <label>{ this.props.label }</label>
        <Select
          id='worker_ids'
          value={selectedOptions}
          options={this.getOptions()}
          onChange={this.handleChange}
          closeMenuOnSelect={false}
          isMulti
          placeholder="Select or type carer names.."
        />
        { this.renderInputs() }
        { this.renderTagSelect() }
      </React.Fragment>
    );
  }
}

WorkerSelect.defaultProps = {
  label: "Select carers you'd like to offer"
}

WorkerSelect.propTypes = {
  workers: PropTypes.array
};
export default WorkerSelect
